import "../../scss/application.scss";
require("intersection-observer");
import { qs, qsa } from "../utils/lib";
import React from "react";
import ReactDOM from "react-dom";
import defineComponents from "../utils/define.jsx";
import Feather from "feather-icons";
import Selectr from "mobius1-selectr";
import Flickity from "flickity";
import AddToCart from "../components/add_to_cart";
import Menu from "../components/menu";
import homeHeroSlider from "../components/home-hero/home-hero";
import FaqAccordion from "../components/faq_accordion";
import OnlineRetailers from "../components/online_retailers";
import clickAndClose from "../components/clickAndClose";
import anchorSx from "../components/anchorSx";
import VideoPlayer from "../components/video_player";
import Search from "../components/search/search";
import LazyLoading from "../components/lazyloading";
import ArtistPopup from "../components/artist-popup";
import ProductFilters from "../components/product_filters";
import ProductBar from "../components/product_bar";
import Navbar from "../components/navbar";
import NewNavbar from "../components/new_navbar";
import ArtistFilters from "../components/artist-filters";
import Configurator from "../components/configurator/index.jsx";
import Cart from "../components/cart";
import Manuals from "../components/manuals";
import Retailers from "../components/retailers.jsx";
import Picker from "../components/product_picker";
import Newsletter from "../components/newsletter";
import Tabs from "../components/tabs";
import Warranty from "../components/warranty";
import Register from "../components/register";
import Immortal from "../components/immortal";
import Epp from "../components/epp";
import Endorseyourself from "../components/endorseyourself";
import Compare from "../components/compare";
import CountryPop from "../components/country_pop";
import Contact from "../components/contact_form";
import Loader from "../components/loader";
import Support from "../components/support_page";
import AudioPlayer from "../components/audio_player";
import ProductPopup from "../components/product_popup";
import Npr from "../components/npr";
import newImmortal from "../components/new_immortal";
import NewWarr from "../components/new_warranty";
import RadioButtons from "../components/radio_buttons";
import NewsletterPopup from "../components/newsletter_popup";
import NewCarousel from "../components/new_carousel";
import AnnouncementBar from "../components/announcement_bar";
import FaqDropdown from "../components/faq_dropdown";
import BreadcrumbWidth from "../components/breadcrumb_width";
import CenterTextPopup from "../components/center-text-popup";
import PageLoad from "../components/page_load";
import Truncate from "../components/truncate";
import NavigationScrollPage from "../components/navigation-scroll";
import responsiveVideo from "../components/responsive_video";
import ProductPageUsers from "../components/product_page_users.js";
import TabsNavigation from "../components/tabs-navigation.js";

window.onload = () => {
    const compare = qs(".compare_new_table");
    if (compare) {
        new Compare(compare);
    }
};

document.addEventListener("DOMContentLoaded", () => {
    PageLoad();

    const ann_bar = qs(".announcement_bar");
    ann_bar && AnnouncementBar();

    const new_c = qs(".js-new-carousel");
    if (new_c) {
        new NewCarousel(new_c);
    }

    const nl_popup = qs(".js-newsletter-popup");
    if (nl_popup) {
        const nl_app = new NewsletterPopup(nl_popup);
        nl_app.init();
    }

    const radio_wraps = qsa(".js-radio-btns");
    radio_wraps.forEach((r) => RadioButtons(r));

    const pr_popup = qs(".promo_pop");
    if (pr_popup) {
        ProductPopup(pr_popup);
    }

    const conf_color = document.querySelector("#conf_color");

    if (conf_color) {
        conf_color.addEventListener("change", () => {
            window.components.Shields.material.reflectivityColor =
                BABYLON.Color3.FromHexString(conf_color.value);
        });
    }

    const new_warranty = qs("#new_warranty");
    if (new_warranty) {
        new NewWarr(new_warranty);
    }

    const npr = qs("#npr");
    if (npr) {
        new Npr(npr);
    }

    const new_immortal = qs("#new_immortal");
    if (new_immortal) {
        new newImmortal(new_immortal);
    }
    const gtags = qsa(".gtag_event");
    gtags.forEach((el) => {
        el.addEventListener("click", () => {
            if (typeof gtag !== "undefined") {
                gtag("event", el.dataset.name, {
                    event_category: el.dataset.category,
                    event_label: el.dataset.label,
                });
            }
        });
    });
    const a_players = qsa(".js-audio-player");
    a_players.forEach((a_p) => {
        const app = new AudioPlayer(a_p);
        app.init();
    });

    Loader();
    const nl = qs(".widget_subscribe");
    if (nl) {
        new Newsletter();
    }
    Tabs();
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    // const supp_b = qs(".support-breadcrumbs");
    // supp_b && new Support(supp_b);

    new defineComponents(
        {
            ".configurator-js": Configurator,
            ".retailers_map": Retailers,
            ".country_pop": CountryPop,
        },
        () => { }
    ).load();

    const warranty_page = document.querySelector("#vmoda_warranty_form");
    if (warranty_page) {
        Warranty();
    }
    const reg_page = document.querySelector(".register-form ");
    if (reg_page) {
        Register();
    }

    const imm_page = document.querySelector(".upgrade_form_cont");
    if (imm_page) {
        Immortal();
    }

    const epp = document.querySelector("#vmoda_epp_form");
    if (epp) {
        Epp();
    }

    const endorse_yourself_page = document.querySelector(
        "#register_form_container"
    );
    if (endorse_yourself_page) {
        Endorseyourself();
    }

    const con_page = document.querySelector("#vmoda_contact_form");
    if (con_page) {
        Contact();
    }
    const products = qsa(".js-product-index");
    products.forEach((p) => {
        new Picker(p);
    });
    const navbar_el = qs(".new_main_header_mobile");
    navbar_el && Navbar(navbar_el);

    const new_navbar_el = qs(".new_main_header");
    new_navbar_el && new NewNavbar(new_navbar_el);

    const pr_details_wrap = qs(".pr_details");
    pr_details_wrap && ProductBar(pr_details_wrap);

    pr_details_wrap && new ProductPageUsers()

    Feather.replace();
    //Home animation
    const jsHomeHero = qs(".js-home-hero");
    if (jsHomeHero) {
        const homeAnimation = new homeHeroSlider();
        homeAnimation.init();
    }
    //lazy loading
    const lazyLoad = new LazyLoading();
    lazyLoad.init();

    //product Carousel

    //cart
    const cart_els = qsa(".add_to_cart");
    cart_els.forEach((el) => {
        new AddToCart(el);
    });

    const cart_page = qs("main.cart");
    cart_page && new Cart(cart_page);

    //manuals

    const manual_page_els = qsa(".pr_manual_card");
    manual_page_els.forEach((el) => {
        new Manuals(el);
    });

    //faq
    const faq_page = qs(".faq_container");

    if (faq_page) {
        const faqAccordion = qsa(".faq_accordion");
        faqAccordion.forEach((el) => {
            const faq_accordion = new FaqAccordion(el);
            faq_accordion.init();
        });
    }

    // Online retailers
    const or_page = qs(".online_retailers");

    if (or_page) {
        const onlineRetailers = qsa(".faq_accordion");

        onlineRetailers.forEach((el) => {
            const online_retailers = new OnlineRetailers(el);
            online_retailers.init();
        });
    }

    //artist
    const art_filters = qs(".js-artist-filters");
    art_filters && new ArtistFilters(art_filters);
    const popupArtist = qsa(".popup-artist");
    popupArtist.forEach((pop) => {
        const popup_artist = new ArtistPopup(pop);
        popup_artist.init();
    });

    //Container, close button
    clickAndClose(".popup", ".popup_container .close");
    clickAndClose(".banner-alert", ".banner-alert_abs-cont_close");

    //Menu click and move
    //Online Retailers
    anchorSx(".country_list a", ".country_container h2");
    //Faq
    anchorSx(".country_list a", ".accordion_title");

    const menu = qs(".menu") || qs(".new_menu");

    if (menu) {
        const menu_c = new Menu(menu);
        menu_c.init();
    }
    const selects = qsa(".mySelect", document);
    selects.forEach((select) => {
        new Selectr(select, {
            searchable: false,
        });
    });

    const breadcrumbs = qs(".js_redirect_select", document);
    if (breadcrumbs) {
        BreadcrumbWidth(breadcrumbs);
        breadcrumbs.addEventListener("change", () => {
            window.location = breadcrumbs.value;
        });
    }

    const wrapper_popup_centered = qs(".js-center-text-popup");
    wrapper_popup_centered && new CenterTextPopup(wrapper_popup_centered);

    const carousels = qsa(".main-carousel");
    carousels.forEach((c) => {
        const flkty = new Flickity(c, JSON.parse(c.dataset.options));
        const prev = c.parentNode.querySelector(".prev");
        const next = c.parentNode.querySelector(".next");
        prev && prev.addEventListener("click", () => flkty.previous());
        next && next.addEventListener("click", () => flkty.next());
        flkty.on("ready", function (index) {
            setCarouselAttributes(c, index);
        });
        flkty.on("settle", function (index) {
            if (flkty.resizeQueued) {
                flkty.resizeQueued = false;
                flkty.onresize();
            }
        });
        flkty.on("dragStart", () => (document.ontouchmove = () => false));
        flkty.on("dragEnd", () => (document.ontouchmove = () => true));
        window.addEventListener("resize", function () {
            if (flkty.isAnimating) {
                flkty.resizeQueued = true;
            } else {
                flkty.onresize();
            }
        });

        const dots_wrapper = c.nextElementSibling;
        if (dots_wrapper) {
            const dots = qsa(".dot", dots_wrapper);
            dots.length > 0 &&
                dots.forEach((dot, i) => {
                    dot.addEventListener("keypress", (e) => {
                        if (e.key === "Enter") {
                            flkty.select(i);
                        }
                    });
                    dot.addEventListener("click", () => {
                        dots.forEach(
                            (dot) =>
                                dot.classList.contains("is-selected") &&
                                dot.classList.remove("is-selected")
                        );
                        flkty.select(i);
                        dot.classList.add("is-selected");
                    });
                });
        }

        flkty.on("change", function (index) {
            const dots_wrapper = c.parentNode.querySelector(
                ".flickity-page-dots"
            );

            if (dots_wrapper) {
                const dots = Array.from(dots_wrapper.querySelectorAll(".dot"));
                if (dots.length > 0) {
                    dots.forEach((dot, i) => {
                        if (i == index) {
                            dot.setAttribute("aria-current", "true");
                            dot.classList.add("is-selected");
                        } else {
                            dot.setAttribute("aria-current", "false");
                            dot.classList.remove("is-selected");
                        }
                    });
                }
            }
            setCarouselAttributes(c, index);
        });

        function setCarouselAttributes(c, index) {
            qsa(".carousel-cell", c).forEach((slide, i) => {
                if (i == index) {
                    slide.setAttribute("aria-hidden", "false");
                    const buttons = qsa("a", slide);
                    buttons &&
                        buttons.forEach((btn) =>
                            btn.setAttribute("tabindex", "0")
                        );
                } else {
                    slide.setAttribute("aria-hidden", "true");
                    const buttons = qsa("a", slide);
                    buttons &&
                        buttons.forEach((btn) =>
                            btn.setAttribute("tabindex", "-1")
                        );
                }
            });
        }
        function resizeButtons() {
            if (c.closest(".carousel-i-c") || c.closest(".reviews_hp")) {
                return false;
            } else {
                if (window.innerWidth <= 1024) {
                    prev.style.display = "none";
                    next.style.display = "none";
                } else {
                    prev.style.display = "initial";
                    next.style.display = "initial";
                }
            }
        }

        prev && next && resizeButtons();

        window.addEventListener("resize", () => {
            prev && next && resizeButtons();
            flkty.resize();
        });
    });
    //lazy loading

    const videos = qsa(".js-video-player");
    videos.forEach((el) => {
        const v = new VideoPlayer(el);
        v.init();
    });
    // Search React Component
    const search_wrapper = qs(".jsx-search");
    const accessories = search_wrapper.dataset.accessories;
    const headphones = search_wrapper.dataset.headphones;

    ReactDOM.render(
        <Search
            locale={search_wrapper.dataset.locale}
            init_accessories={JSON.parse(accessories)}
            init_headphones={JSON.parse(headphones)}
            translations={JSON.parse(search_wrapper.dataset.translations)}
            cta={search_wrapper.dataset.cta}
        />,
        search_wrapper
    );

    const filter_components = qsa(".product_filters", document);
    filter_components.forEach((el) => {
        const el_app = new ProductFilters(el);
        el_app.init();
    });

    const faq_accs = document.querySelectorAll(".js-faq-dropdown");
    faq_accs.forEach((el) => new FaqDropdown(el));

    const truncate_comp = document.querySelector(".js-truncate");
    truncate_comp && new Truncate(truncate_comp);

    const navigation_scroll_page = document.querySelector(
        ".js-navigation-scroll"
    );
    navigation_scroll_page && new NavigationScrollPage(navigation_scroll_page);

    const responsiveVideos = qsa("[data-video-responsive]");
    responsiveVideos.length > 0 && responsiveVideo(responsiveVideos);

    const tabNavigationComponent = qs(".js-tab-navigation");
    tabNavigationComponent && new TabsNavigation(tabNavigationComponent);
});
