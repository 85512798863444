import React from 'react'
import { useState, useEffect } from 'react'
import {qs,qsa, fetchJSON} from '../../utils/lib'
import SearchGrid from './search_grid'
import * as Icon from 'react-feather';
import { last } from 'lodash-es';
import trap from '../trap.js'

const Search = (props) => {
    const [locale] = useState(props.locale)
    const [t] = useState(props.translations)
    const [cta] = useState(props.cta)
    const [open, setIsOpen] = useState(false)
    const [array_h, setArrayH] = useState([])
    const [init_h, setInitH] = useState(props.init_headphones)
    const [array_a, setArrayA] = useState([])
    const [init_a, setInitA] = useState(props.init_accessories)    
    const [title, setTitle] = useState(t.title)
    const [results_a,setResultsA] = useState(0)
    const [results_h,setResultsH] = useState(0)
    const [string, setString] = useState('')
    const [typed, isTyped] = useState(false)

    useEffect(() => {
        const open_search_form_btns = qsa('.search_button')
        const close_search_form_btn = qs('.js-search-close')
        open_search_form_btns.forEach(btn => {
            btn.addEventListener('click', () => animateForm(1))
        })
        close_search_form_btn.addEventListener('click', () => animateForm(-1)) 
    },[])

    const searchItem = async (props) => {
        setString(props.target.value)
        const q_route = `${locale}?q=`
        if (props.target.value == "" || props.target.value.length < 2 ) {
            setArrayH(init_h)
            setArrayA(init_a)
            setResultsH(0)
            setResultsA(0)
            setTitle(t.title)
            isTyped(false)
        } else {
            if (typeof gtag !== "undefined") {
                gtag('event', 'page_view', {
                    page_title: 'Search',
                    page_path: q_route + props.target.value,
                  })
              }
              
            const data = await fetchJSON(q_route)
            let results = data.filter((value) => {
                return value.search_str.toLowerCase().includes(props.target.value.toLowerCase()) || value.title.toLowerCase().includes(props.target.value.toLowerCase())
            })
            let new_array_h = []
            let new_array_a = []
            results.forEach((el) => {
                if (el.category == "headphones") {
                    new_array_h.push(el)
                } else {
                    new_array_a.push(el)
                }
            })
            setArrayH(new_array_h)
            setArrayA(new_array_a)
            setResultsH(new_array_h.length)
            setResultsA(new_array_a.length)
            setTitle(t.cta_search_results_title)
            isTyped(true)
        }
    }
    const animateForm = (dir) => {
        const search_form = qs('.js-search')
        const input = qs('.jsx-search-input')
        if (dir > 0) {
            search_form.classList.add('active')
            setArrayH(init_h)
            setArrayA(init_a)
            setIsOpen(true)
            input.focus()
            trap(search_form)
        } else {
            search_form.classList.remove('active')
            input.value = ""
            setArrayH(init_h)
            setArrayA(init_a)
            setResultsH(0)
            setResultsA(0)
            setTitle("Featured products")
            setIsOpen(false)
            isTyped(false)
            qs('.search_button') ? qs('.search_button').focus() : document.body.focus()
        }
    }
    return (
        <section tabIndex="-1">
            <nav className="search_nav">
                <div className="search_nav_form">
                    <div className="col col-1 text-right">
                        <Icon.Search color="white" className="c-light" />          
                    </div>
                    <label htmlFor="search" className="sr-only">Search</label>
                    <input onChange={searchItem} type="text" id="search" placeholder={t.cta_search} className="col jsx-search-input first-focusable-element" name="search" />
                    <button className="col col-1 js-search-close" type="button" aria-label="Close">
                        <Icon.X color="white" className="c-light js-search-close" />   
                    </button>
                </div>
            </nav>
            <SearchGrid 
                string={string}
                headphones={array_h}
                accessories={array_a} 
                title={title}
                locale={locale}
                cta={cta}
                t={t}
                isTyped={typed}
                results={array_h.concat(array_a)}
                no_res_prods={[init_h[0],init_a[0]]}
            />
        </section>
    )
}
export default Search;