import gsap from "gsap";
import { qs, qsa } from "../utils/lib";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import Selectr from "mobius1-selectr";
gsap.registerPlugin(ScrollToPlugin);

class NavigationScrollPage {
    constructor(el) {
        this.el = el;
        this.links = qsa("[data-scroll]");
        this.sections = [];
        this.setScrollPosition = 0;
        this.pr_selects = document.querySelectorAll(".product_select");
        this.scrollto = qsa(".js_scroll_to");
        this.isAnchoring = false;
        this.offset = 150;
        this.init();
    }

    init() {
        this.pr_selects.forEach((pr_select) => {
            new Selectr(pr_select, {
                searchable: false,
            });
            pr_select.addEventListener("change", (e) => {
                const target = qs(e.target.value);
                gsap.to(window, {
                    duration: 1,
                    scrollTo: this.getPosition(target).y - 100,
                });
            });
        });
        this.links.forEach((link) => {
            const id = link.getAttribute("data-target");
            const section = document.querySelector(id);

            if (section) {
                this.sections = [
                    ...this.sections,
                    {
                        section: section,
                        position: 0,
                    },
                ];
            }
        });
        this.scrollto.forEach((el) => {
            el.addEventListener("click", () => {
                this.isAnchoring = true;
                const target = qs(el.dataset.target);
                gsap.to(window, {
                    duration: 1,
                    scrollTo: this.getPosition(target).y - 150,
                    onComplete: () => (this.isAnchoring = false),
                });
                this.scrollto.forEach((btn) => {
                    if (btn == el) {
                        btn.classList.add("underlined");
                        btn.setAttribute("aria-current", "true");
                    } else {
                        btn.classList.remove("underlined");
                        btn.setAttribute("aria-current", "false");
                    }
                });
                if (el.classList.contains("js-active-text-bar")) {
                    document
                        .querySelector('[data-target="#product-perks"]')
                        ?.classList.add("underlined");
                }
            });
        });
        if (window.innerWidth > 768) {
            window.addEventListener("scroll", () => {
                if (!this.isAnchoring) {
                    this.setPositions();
                }
            });
        }
    }

    getPosition(element) {
        var xPosition = 0;
        var yPosition = 0;
        while (element) {
            xPosition +=
                element.offsetLeft - element.scrollLeft + element.clientLeft;
            yPosition +=
                element.offsetTop - element.scrollTop + element.clientTop;
            element = element.offsetParent;
        }

        return { x: xPosition, y: yPosition };
    }

    setPositions() {
        this.setScrollPosition = window.scrollY;
        this.checkSectionPosition();
        this.animation();
    }

    checkSectionPosition() {
        this.sections.forEach((item) => {
            item.position = parseInt(
                item.section.getBoundingClientRect().top + window.scrollY
            );
        });
    }
    animation() {
        this.sections.forEach((item) => {
            if (this.setScrollPosition > item.position - this.offset) {
                this.animateLinks(item.section);
            }
        });
    }

    animateLinks(el) {
        const id = el.getAttribute("id");
        const link = this.links.find(
            (el) => el.getAttribute("data-target") == `#${id}`
        );
        this.links.forEach((nav_link) => {
            if (link == nav_link) {
                nav_link.classList.add("underlined");
            } else {
                nav_link.classList.remove("underlined");
            }
        });
    }
}

export default NavigationScrollPage;
