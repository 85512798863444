import {qs,qsa} from '../utils/lib'
import gsap from 'gsap'
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);


export default class Compare {
    constructor(el){
        this.el = el
        this.cont = qs('.compare_table_cont')
        this.cells = qsa('.lockable_col',this.el)
        this.height;
        this.toggles = qsa('.pr_card_toggle')
        this.rows = qsa('.compare_row')
        this.active = null
        this.width = this.cont.getBoundingClientRect().width;
        this.cell_size = this.width / 4
        this.prev = qs('.compare_btn.prev')
        this.next = qs('.compare_btn.next')
        this.interval;
        this.direction;
        this.init()
    }
    init() {
        if (window.innerWidth > 640) {
            this.resize()
        }
      window.addEventListener('resize', () => {
        if (window.innerWidth > 640) {
            this.resize()
        }
      });
      this.toggles.forEach((el,i) => {
          el.addEventListener("click",() => {
              const index = el.dataset.index
              if ( this.active && this.active == index) {
                  this.active = null
              } else {
                  this.active = index
              }
              this.apply()
          })
      })
     this.prev.addEventListener("click", () => {
        gsap.to(this.el, {scrollTo: { x: "-=" + this.cell_size + "px" }});
     })
     this.next.addEventListener("click", () => {
        gsap.to(this.el, {scrollTo: { x: "+=" + this.cell_size + "px" }});
     })
     
    }

    scroll() {
        gsap.to(this.el, {scrollTo: { x: "+=" + (this.direction * 100) + "px" }});
    }
    resize() {
        

        if (   window.innerWidth < 1055 ) {

            this.width = this.cont.getBoundingClientRect().width - 40;
            this.cell_size = this.width / 2

        } else {

            this.width = this.cont.getBoundingClientRect().width - 120;
            this.cell_size = this.width / 4
            
        }
       
        this.cells.forEach((cell) => {
            cell.style.width = this.cell_size + "px"
        })
    }

    apply() {
        this.rows.forEach((row,index) => {
            
            const cells = qsa('.lockable_col',row)
            cells.forEach((cell) => {
                cell.style.height = "";
            })
            let h = row.clientHeight;  // height with padding
            cells.forEach((cell) => {
                cell.style.height =  h + "px"
            })
        });
        this.toggles.forEach((el,i) => {
            if ( i == this.active ) {
                el.classList.add("active")
            } else {
                el.classList.remove("active")
            }
        });
        this.rows.forEach((row) => {
            const lockables = qsa('.lockable_col',row)
            lockables.forEach((el,i) => {
                if ( i == this.active ) {
                    el.classList.add("active")
                    el.setAttribute("aria-current","true")
                    if (el.querySelector('.sr-only')) {
                        el.querySelector('.sr-only').innerHTML = 'Compared Product'
                    }

                } else {
                    el.classList.remove("active")
                    el.setAttribute("aria-current",false)
                    if (el.querySelector('.sr-only')) {
                        el.querySelector('.sr-only').innerHTML = 'Product'
                    }
                }
            })
            
        });
        if ( this.active ) {
            this.el.classList.add("locked")
            this.el.style.paddingLeft = this.cell_size + "px";
        } else {
            this.el.classList.remove("locked")
            this.el.style.paddingLeft = 0
        }
    }
}