import { qs, qsa } from "../utils/lib";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
export default function anchorSx(container, categories) {
  let menu = qsa(container);
  let category = qsa(categories);

  for (let i = 0; i < menu.length; i++) {
    menu[i].addEventListener("click", (evt) => {
      menu.forEach((element) => {
        element.classList.remove("active");
        element.setAttribute('aria-current','false');
      });
      evt.preventDefault()
      gsap.to(window, 0.7, { scrollTo: { y: category[i], offsetY: 150 } });
      evt.target.classList.add("active");
      evt.target.setAttribute('aria-current','true');
      if (category[i]) {
        category[i].focus();
      }
      
    });
  }
}
